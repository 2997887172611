import * as yup from 'yup';
import { getBaseStringValidator, getEmailValidator, getPhoneValidator } from '@utils';

export const createClientValidation = () => {
    const validator = getBaseStringValidator();

    return yup.object({
        email: getEmailValidator(),
        first_name: validator.max(255),
        last_name: validator.max(255),
        phone: getPhoneValidator(),
        send_notification: yup.bool(),
    });
};
